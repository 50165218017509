<template>
  <div>
    <a href="http://stat.balaan.io:3000/" target="_blank" class="btn btn-success mb-2">메타베이스로 이동</a>

    <b-card>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list">
            <i class="fa fa-search"></i> 검색
          </b-button>
        </b-input-group-prepend>
        <b-form-input id="search" type="text" placeholder="지표명, 컬럼정의를 넣어주세요" v-model="form.search" @keypress.enter.prevent.stop="list" v-focus></b-form-input>
      </b-input-group>

      <div class="mb-3">
        <b-button class="btn-sm m-1" variant="primary" @click="toggleDashboard">전체 선택</b-button>
        <b-button class="btn-sm m-1" variant="light" @click="setDashboard({dash_id:'공통'})">공통</b-button>
        <b-button class="btn-sm m-1" variant="light" @click="setDashboard({dash_id:'전사'})">전사</b-button>
        <b-button class="btn-sm m-1" variant="light" @click="setDashboard({dash_id:'제품'})">제품</b-button>
        <b-button class="btn-sm m-1" variant="light" @click="setDashboard({dash_id:'영업'})">영업</b-button>
        <b-button class="btn-sm m-1" variant="light" @click="setDashboard({dash_id:'상품'})">상품</b-button>
        <v-select v-model="form.dashboard" multiple :options="dashboard" label="dash_id" placeholder="전체 대시보드"></v-select>
      </div>
      <b-row>
        <b-col cols="6" lg="7">
          <div><small>상태</small></div>
          <b-form-radio-group class="col-form-label" id="stockStatus" v-model="form.status" :options="[
            {text: '전체', value: 'ALL'},
            {text: '사용중', value: 'use'},
            {text: '미사용', value: 'deprecated'},
            {text: '삭제', value: 'delete'}
          ]">
          </b-form-radio-group>
        </b-col>
        <b-col cols="6" lg="4">
          <div><small>미노출</small></div>
          <b-form-radio-group class="col-form-label" id="displayStatus" v-model="form.display_status" :options="[
            {text: '전체', value: 'ALL'},
            {text: '노출', value: 'view'},
            {text: '미노출', value: 'notview'}
          ]">
          </b-form-radio-group>
        </b-col>
      </b-row>
      <div class="m-1 pull-right">
        <b-button :variant="`${detail ? 'outline-' : ''}info`" @click="detail = !detail">상세정보</b-button>
      </div>
      <div class="m-1 pull-right">
        <b-button :variant="`${editMode ? 'outline-' : ''}info`" @click="editMode = !editMode">수정모드</b-button>
      </div>
      <b-button class="m-1" variant="primary" @click="list">검색</b-button>
    </b-card>
    <div class="clearfix">
      <b-button class="m-1" variant="success" @click="_=>{$refs.xlsx.value = null;$refs.xlsx.click()}">XLSX Upload</b-button>
      <input type="file" ref="xlsx" data-type="normal" style="display: none" @change="handleXlsx">

      <b-button class="m-1 pull-right" variant="success" @click="xlsx()">XLSX Down</b-button>
    </div>
    <b-card>
      <div slot="header">
        <strong class="pull-left">지표 정의 </strong>
      </div>
      <b-row>
        <b-col b-col :lg="detail ? 8 : 12">
          <c-table :table-data="items" :fields="fields" :perPage.sync="perPage" :isBusy="isBusy" :caption="items.length + ' 개 상품'" v-on:row-clicked="rowClicked" ></c-table>
        </b-col>
        <b-col v-if="detail" class="border-left" lg="4">
          <template v-if="item.id">
            <div class="title-sm">기본정보</div>
            <b-row>
              <b-col class="mb-2" cols="6" lg="6">
                <small>대시보드 ID</small><br/>
                <b-input v-if="editMode" v-model.number="item.dash_id"></b-input>
                <label v-else class="col-form-label">{{item.dash_id}}</label>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mb-2" cols="6" lg="6">
                <small>지표명</small><br/>
                <b-input v-if="editMode" v-model.number="item.name"></b-input>
                <label v-else class="col-form-label">{{item.name}}</label>
              </b-col>
              <b-col class="mb-2" cols="6" lg="6">
                <small>지표 영어명</small><br/>
                <b-input v-if="editMode" v-model.number="item.id"></b-input>
                <label v-else class="col-form-label">{{item.id}}</label>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mb-2" cols="12" lg="12">
                <small>컬럼 정의</small><br/>
                <b-input v-if="editMode" v-model.number="item.info"></b-input>
                <label v-else class="col-form-label">{{item.info}}</label>
              </b-col>
              <b-col class="mb-2" cols="6" lg="12">
                <small>수식</small><br/>
                <b-input v-if="editMode" v-model.number="item.calc"></b-input>
                <label v-else class="col-form-label">{{item.calc}}</label>
              </b-col>
              <b-col class="mb-2" cols="6" lg="3">
                <small>지표 상태</small><br/>
                <b-form-select v-model="item.status"  :disabled="!editMode" :options="[
                    {text: '사용중', value: 'use'},
                    {text: '미사용', value: 'deprecated'},
                    {text: '삭제', value: 'delete'}
                  ]">
                </b-form-select>
              </b-col>
              <b-col class="mb-2" cols="6" lg="3">
                <small>보기 상태</small><br/>
                <b-form-select v-model="item.display_status" :disabled="!editMode" :options="[
                    {text: '노출', value: 'view'},
                    {text: '미노출', value: 'notview'}
                  ]">
                </b-form-select>
              </b-col>
              <b-col class="mb-2" cols="6" lg="3">
                <small>순서</small><br/>
                <b-input v-if="editMode" v-model.number="item.order"></b-input>
                <label v-else class="col-form-label">{{item.order}}</label>
              </b-col>

              <b-col cols="12" lg="12">
              <label>상세 설명</label>
              <b-textarea rows="10" v-model="item.desc" :readonly="!editMode"></b-textarea>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col class="mb-2" cols="6" lg="6">
                <small>담당자</small><br/>
                <b-input v-if="editMode" v-model.number="item.manager"></b-input>
                <label v-else class="col-form-label">{{item.manager}}</label>
              </b-col>
              <b-col class="mb-2" cols="6" lg="6">
                <small>데이터 소스</small><br/>
                <b-input v-if="editMode" v-model.number="item.org"></b-input>
                <label v-else class="col-form-label">{{item.org}}</label>
              </b-col>
            </b-row>
            <div class="m-1 pull-right">
              <b-button v-if="editMode"  variant="primary" @click="save()">저장</b-button>
            </div>
          </template>

          <div v-else class="text-center mt-5">
            지표를 선택해주세요
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<style>
  @import '~handsontable/dist/handsontable.full.css';
</style>
<script>
import cTable from '@/views/TableBase.vue'
import {getJson, postJson} from '@/shared/api'
import {down, readXlsx} from '@/shared/impexp'
import * as C from 'balaan_constants'
import vSelect from 'vue-select'
import * as utils from '@/shared/utils'
export default {
  name: 'indicator',
  title: '지표 정의',
  components: {cTable, vSelect},

  data() {
    return {
      dashboard: [{dash_id:"공통"}, {dash_id:"전사"}, {dash_id:"제품"}, {dash_id:"영업"}, {dash_id:"상품"}],
      item: {},
      item_org: {},
      items: [],
      form: {
        search: '',
        dashboard: [],
        status: 'ALL', display_status: 'ALL',
      },
      fields:[
        {key:'dash_id', label: '대시보드 ID', style:{width:'100px'}},
        {key:'name', label:'지표명', style:{width:'100px'}},
        {key:'id', label:'지표 영어명'},
        {key:'info', label: '컬럼 정의'},
        // {key:'indicator_cal', label: '수식'},
        // {key:'desc', label: '상세 설명'},
        {key:'status', label: '지표 상태'},
        {key:'display_status', label: '보기 상태'},
        {key:'order', label: '순서'},
       ],
      perPage: 20,

      busy: {search: false, xlsxDown:false, xlsxUp:false},
      isBusy: false,
      detail: false,
      editMode:false
    }
  },
  async created() {
    this.list();
  },
  methods: {
    rowClicked(record, index) {
      // this.item = record;
      this.item_org = record;
      this.item = JSON.parse(JSON.stringify(record));
    },
    async save() {
      let modifiedCols = Object.keys(this.item).filter(e=>JSON.stringify(this.item_org[e]) !== JSON.stringify(this.item[e]));
      if (this.editMode) {
        // debugger
        if (modifiedCols.length) {
          let item = {_id:this.item._id};
          modifiedCols.forEach(e=>item[e] = this.item[e]);
          let j = await postJson('/data/updateIndicator', {item: {...item}});
          if (j) {
            // this.syncItem(j);
            this.list();
          }
        }
      }
      // let j = await postJson('/data/updateIndicator', {form:{...this.form}});
      // this.item = record;
    },
    async list() {
      this.item_org = {};
      this.item = {};
      let j = await postJson('/data/indicator', {form:{...this.form}});
      // this.items = dataObject;
      this.items = j.list;
      // this.item = j.list[0];
    },
    toggleDashboard() {
      this.form.dashboard = this.form.dashboard.length === this.dashboard.length ? [] : this.dashboard.map(e=>e);
    },
    setDashboard(obj) {
      let dashboard = this.dashboard;
      Object.entries(obj).forEach(([k,v])=>{
        dashboard = dashboard.filter(e=>e[k]===v);
      });
      this.form.dashboard = dashboard;
    },
    async handleXlsx(event) {
      let file = (event.dataTransfer || event.target).files[0];
      if (!file || !file.name.endsWith('xlsx') && !file.name.endsWith('xls')) return utils.alert('xlsx 파일을 업로드해주세요');
      if (!confirm(`선택한 Excel 파일로 전체 데이터를 변경하겠습니까?`)) return;
      let {headers, rows} = await readXlsx(file);
      this.uploadXlsx(event.target, headers, rows);
    },
    xlsx() {
      let rows = this.items;
      let entries = Object.entries(C.INDICATOR_COLUMNS)
        , keys = entries.map(([k,v])=>k)
        , values = entries.map(([k,v])=>v);
      let code = keys;
      let name = values.map(e=>e.name);
      down(rows, name, code, `${'indicator'}_${utils.dt()}`, 'xlsx');
    },
    async uploadXlsx(target, headers, rows) {
      /**
       * 발란 형식 업로드
       */

      let entries = Object.entries(C.INDICATOR_COLUMNS)
              , keys = entries.map(([k,v])=>k)
              , values = entries.map(([k,v])=>v);
      let code = keys;
      let name = values.map(e=>e.name);
      let colNameMap = {};
      entries.forEach(([k,v])=>{
        v.key = k;
        colNameMap[v.name] = v;
      });
      let unknown = headers.filter(e=>!colNameMap[e]);
      if (unknown.length) return alert('알 수 없는 컬럼들이 있습니다:\n'+unknown.join('\n'));
      // let notAllow = headers.filter(e=>!colNameMap[e].upload);
      // if (notAllow.length) return utils.alert('업로드 할 수 없는 컬럼들이 있습니다:\n'+notAllow.join('\n'));
      // let required = values.filter(e=>e.required).map(e=>e.name).filter(e=>!~headers.indexOf(e));
      // if (required.length) return alert('필수 컬럼이 빠져있습니다:\n'+required.join('\n'));

      let wrongRows = [];
      rows.forEach((e, i)=>{
        let wrongCols = [];
        headers.forEach(h=>{
          let tester = colNameMap[h].test;
          if (e[h] != null && e[h] !== '' && tester && !tester.test(e[h])) {
            wrongCols.push(`${h}: ${e[h]}`);
          }
          if ((e[h] == null || e[h] === '') && colNameMap[h].required) {
            wrongCols.push(`${h}: (비어있음)`);
          }
        });
        if (wrongCols.length) wrongRows.push({idx:i, cols:wrongCols});
      });
      if (wrongRows.length) return alert('다음 컬럼들의 값이 올바르지 않습니다:\n'+wrongRows.map(e=>`${e.idx+3} 번째줄 ${e.cols.map(e=>e).join(', ')}`).join('\n'));

      rows = rows.map(e=>{
        let obj = {};
        headers.forEach(name=>{
          let col = colNameMap[name];
          if (e[name] === undefined) {
            obj[col.key] = "";
          } else {
            obj[col.key] = e[name];
          }
        });
        return obj;
      });

      // 컬럼 정의에 type 이 있는 경우 해당 타입으로 casting 한다.
      let typeHeaders = headers.filter(h=>colNameMap[h].type);
      rows.forEach(row=>{
        typeHeaders.forEach(h=>{
          let {key, type} = colNameMap[h];
          if (type === 'number') {
            row[key] = +row[key];
          } else if (type === 'string') {
            row[key] = ''+row[key];
          }
        });
      });
      console.log(rows);
      let j = await postJson('/data/uploadIndicators', {rows});
      if (j.ok === 1) {
        utils.alert(`${j.cnt}건 정상적으로 업로드 되었습니다`);
        this.list();
      } else if (j.ok === -1) {
        let body = `<h4>${j.msg}</h4>` + j.errors.map(e=>`${e.error||''}`).join('<br/>');
        this.$modal.show({title:'업로드 에러 확인', html:'<pre>' + body + '</pre>'});
      }
      target.value = "";
    },
  }
}
</script>
